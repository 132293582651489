import React from 'react';
import AllProducts from './AllProducts';

const Dashboard = () => {
	return (
		<div className="p-4">
			<h1 className="py-4 text-2xl font-gray-800 font-bold">
				Products Dashboard
			</h1>
			<div className="flex flex-col">
				<AllProducts />
			</div>
		</div>
	);
};

export default Dashboard;
