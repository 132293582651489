import React from 'react';

import { CiSearch } from 'react-icons/ci';

const ProductSearch = () => {
	return (
		<div className="flex items-center gap-x-2 rounded-lg bg-gray-50 overflow-hidden border px-4 w-full sm:max-w-xs">
			<div className="icon text-gray-400">
				<CiSearch />
			</div>
			<input
				type="search"
				className="focus:outline-none bg-transparent py-1.5"
			/>
		</div>
	);
};

export default ProductSearch;
