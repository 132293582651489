import React, { useState } from 'react';
import ProductSearch from '../../../../components/forms/ProductSearch';
import Dropdown from '../../../../components/forms/Dropdown';

const AllProducts = () => {
	const [selectedValue, setSelectedValue] = useState({
		id: '1',
		value: 'Market',
		type: '',
	});
	return (
		<div className="bg-white rounded-lg py-5 px-2">
			<header className="flex flex-col md:flex-row md:items-center gap-5">
				<div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:flex-1">
					<h1 className="text-gray-800 dark:text-app-neutral-50 text-lg font-semibold before:content-['m']before:rounded-lg before:w-4 before:aspect-[2/4] before:bg-red-200 before:rounded-sm flex items-center gap-2">
						Overview
					</h1>
					<ProductSearch />
				</div>
				<nav className="sm:flex-1 hidden lg:block">
					<ul className="sm:flex items-center justify-between md:justify-end md:gap-5 gap-2 text-sm text-gray-600">
						<li className="py-1.5 px-2 border rounded-lg">
							Market
						</li>
						<li className="py-1.5 px-2 border rounded-lg">
							Traffic Sources
						</li>
						<li className="py-1.5 px-2 border rounded-lg">Views</li>
					</ul>
				</nav>
				<div className="lg:hidden">
					<Dropdown
						data={[
							{ id: '1', value: 'Market', type: '' },
							{ id: '2', value: 'Traffic Resources', type: '' },
							{ id: '3', value: 'Views', type: '' },
						]}
						selectedValue={selectedValue}
						setSelectedValue={setSelectedValue}
					/>
				</div>
			</header>
		</div>
	);
};

export default AllProducts;
